import { polyfill } from 'es6-promise'
polyfill()

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'

import 'core-js/stable';

Vue.use(Vuex)

// create store
export default new Vuex.Store({})

import Vue from 'vue/dist/vue.esm'
import store from '../store/store.js'
import FilterableTable from '@unep-wcmc/wcmc-components'
import Turbolinks from "turbolinks"
import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)
Vue.use(FilterableTable, { store })
Turbolinks.start()

const addEventTracking = () => {
  Array.prototype.forEach.call(document.querySelectorAll('[data-analyticsevent]'), element => {
    element.onclick = () => {
      if (ga) {
        ga('send', 'event', 'Download', element.dataset.analyticsevent)
      }
    }
  })
}

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('v-app')) {
    const app = new Vue({
      el: '#v-app',
      store,
      mounted: function () {
        // hacky solution to make the links in modal clickable
        const table = document.querySelector('.page-truncation .table-body');
        if (table) {
          table.addEventListener('click', (event) => {
            // workaround since the full table is not available straight away on mounted
            // if the modal opening button is clicked
            if (event.target._prevClass == 'button' || event.target._prevClass == 'svg__arrow' || event.target.tagName == 'svg' || event.target.tagName == 'rect') {
              // fetch the last child
              const linkElement = document.querySelector('.page-truncation .modal__content > div:last-child');
              const link = linkElement.childNodes[2].nodeValue
                if (link.includes('http')) {
                  // attach a new link element
                  const elem = document.createElement('a')
                  elem.setAttribute('href', link)
                  elem.innerText = link
                  linkElement.appendChild(elem)
                  // hide the original text node - it needs to stay in the DOM, so vue updates its value in a new modal for another row
                  linkElement.style.fontSize = '0px'
                  linkElement.children.forEach((c) => c.style.fontSize = '1rem')

                  // clear on modal close
                  const modalCloseBtn = document.querySelector('.page-truncation .modal .modal__close');
                  if (modalCloseBtn) {
                    function cleanup() {
                      // remove the link element
                      elem.remove()
                      linkElement.style.fontSize = '1rem'
                    }
                    modalCloseBtn.addEventListener('click', cleanup)
                    modalCloseBtn.children.forEach((c) => c.addEventListener('click', cleanup));
                  }
              }
            }
          })
        }
      }
    })
  }
  addEventTracking()
})
